// extracted by mini-css-extract-plugin
export var container = "menu-module--container--QX1dY";
export var containerScrolled = "menu-module--container-scrolled--h8pWX";
export var desktopLink = "menu-module--desktop-link--18K3M";
export var infoBar = "menu-module--info-bar--KvSVV";
export var infoBarItem = "menu-module--info-bar-item--FlEOh";
export var infoBarItemIcon = "menu-module--info-bar-item-icon--TNE6r";
export var infoBarItemText = "menu-module--info-bar-item-text--YwSGE";
export var infoBarItemsWrapper = "menu-module--info-bar-items-wrapper--eiLY7";
export var infoBarLogo = "menu-module--info-bar-logo--zlxnu";
export var left = "menu-module--left--x2yV-";
export var logotype = "menu-module--logotype--WqsTH";
export var logotypeScrolled = "menu-module--logotype-scrolled--tqV93";
export var menuIcon = "menu-module--menu-icon--8Nlgx";
export var onTop = "menu-module--on-top--fTQqy";
export var rightDesktop = "menu-module--rightDesktop--yZe9b";
export var rightMobile = "menu-module--rightMobile--G3ZYM";