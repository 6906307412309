import React from "react"
import * as styles from "./layout.module.scss"
import { Helmet } from "react-helmet"
import 'bootstrap/dist/css/bootstrap.min.css';
import 'slick-carousel/slick/slick.css';
import Menu from "../menu/menu";
import ModalMenu from "../modal-menu/modal-menu";
import FooterSection from "../../page-sections/footer-section/footer-section";
import { scrollToId } from '../../global-functions/scroll-to';
import { navigate } from "gatsby";

export const MeetUsId = 'meet-us-section';
export const NewsId = 'news-section';
export const RestaurantId = 'restaurant-section';
export const ContactSectionId = 'contact-section';

export default function Layout({ children }) {

  const [isOffcanvasOpen, setIsOffcanvasOpen] = React.useState(false);

  function openOffcanvas() {
    setTimeout(() => {
      setIsOffcanvasOpen(true);
    }, 10);
  }

  function closeOffcanvas() {
    setIsOffcanvasOpen(false);
  }

  function onOffcanvasLinkClick(id, isPage) {
    closeOffcanvas();
    setTimeout(() => {
      if (!isPage) {
        scrollToId(id);
        return;
      }
      navigate(id);
    }, 150);
  }

  return <>
    <Helmet>
      <meta charSet="utf-8" />
      <html lang="pl" />
      <title>Beefteka</title>
      <meta name="description" content="Beefteka - Restauracja w Kole" />
      <meta name="theme-color" content="#1C1D1E"></meta>
    </Helmet>

    <div className={styles.container}>
      <Menu
        onMobileMenuClick={() => openOffcanvas()}
        menuList={[
          {
            text: "O nas",
            onClick: () => { scrollToId(MeetUsId); }
          },
          {
            text: "Aktualności",
            onClick: () => { scrollToId(NewsId); }
          },
          {
            text: "Menu",
            onClick: () => { navigate("/menu"); }
          }, {
            text: "Browar Koło",
            onClick: () => { window.location.href = "https://browarkolo.pl"; }
          }, {
            text: "Kontakt",
            onClick: () => { navigate("/contact"); }
          }
        ]}
        topBarItems={[
        ]}
      />
      <main>
        {children}
      </main>
      <FooterSection
        menuList={[
          {
            text: "O nas",
            onClick: () => { scrollToId(MeetUsId); }
          },
          {
            text: "Aktualności",
            onClick: () => { scrollToId(NewsId); }
          },
          {
            text: "Menu",
            onClick: () => { navigate("/menu"); }
          }, {
            text: "Browar Koło",
            onClick: () => { window.location.href = "https://browarkolo.pl"; }
          }, {
            text: "Kontakt",
            onClick: () => { navigate("/contact"); }
          }
        ]}
      />
      <span id={ContactSectionId}></span>
    </div>

    <ModalMenu
      show={isOffcanvasOpen}
      onCloseClick={() => closeOffcanvas()}
      menuList={[
        {
          text: "O nas",
          onClick: () => { onOffcanvasLinkClick(MeetUsId); }
        },
        {
          text: "Aktualności",
          onClick: () => { onOffcanvasLinkClick(NewsId); }
        },
        {
          text: "Menu",
          onClick: () => { onOffcanvasLinkClick("/menu", true); }
        }, {
          text: "Browar Koło",
          onClick: () => { window.location.href = "https://browarkolo.pl"; }
        }, {
          text: "Kontakt",
          onClick: () => { onOffcanvasLinkClick("/contact", true); }
        }
      ]}
      contactInfoList={[
        {
          header:"Zadzwoń do nas",
          text:["+48 535 76 76 76"]
        },
        {
          header:"Odwiedź nas",
          text:["ul. Henryka Sienkiewicza 35","62-600 Koło"]
        },
        {
          header:"Napisz do nas",
          text:["kontakt@browarkolo.pl"]
        }
      ]}
    />
  </>
}
