import React from "react"
import * as styles from "./modal-menu.module.scss"
import {Modal} from "react-bootstrap";
import LogotypeSVG from "../../svg/beefteka_logo_white.svg";
import VerticalSpacingRow from "../vertical-spacing/vertical-spacing";
import VerticalSpacingSmallRow from "../vertical-spacing-small/vertical-spacing-small";
import "./modal-menu.css"

export default function ModalMenu(props) {

    function onCloseClick() {
        props.onCloseClick();
    }

    return <>
        <Modal
            show={props.show}
            fullscreen="true"
            onHide={() => onCloseClick()}
            dialogClassName={styles.modalDialogX}
            contentClassName={styles.modalContentX}
        >
            <Modal.Body>
                <div className={styles.buttonWrapper}>
          <span
              className={`icon-close-icon ${styles.btnClose}`}
              onClick={() => onCloseClick()}
          ></span>
                </div>

                <div
                    className={styles.listWrapper}
                    onClick={() => onCloseClick()}
                >
                    <div className={styles.listItem}>
                        <div className={styles.logoWrapper}>
                            <LogotypeSVG/>
                        </div>
                    </div>
                    {props.menuList.map((item, index) =>
                        <div
                            className={styles.listItem}
                            onClick={() => item.onClick()}
                            key={index}
                        >
                            {item.text}
                        </div>
                    )}
                    <VerticalSpacingRow/>
                    <VerticalSpacingSmallRow/>
                    {props.contactInfoList.map((item, index) =>
                        <div className={styles.contactListItem} key={index}>
                            <div
                                className={styles.contactHeader}                                
                            >
                                {item.header}
                            </div>
                                {item.text.map((item,index)=>
                                <div className={styles.contactText} key={index}>{item}</div>)}
                        </div>
                    )}
                </div>

            </Modal.Body>
        </Modal>
    </>
}
