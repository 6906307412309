// extracted by mini-css-extract-plugin
export var btnClose = "modal-menu-module--btn-close--k9aUF";
export var buttonWrapper = "modal-menu-module--button-wrapper--LMXWm";
export var closeButton = "modal-menu-module--close-button--KhAXM";
export var closeButtonWrapper = "modal-menu-module--close-button-wrapper--XKXVU";
export var contactHeader = "modal-menu-module--contact-header--ae2xs";
export var contactListItem = "modal-menu-module--contact-list-item--eXyEr";
export var contactText = "modal-menu-module--contact-text--Ovxko";
export var listItem = "modal-menu-module--list-item--wXwzT";
export var listWrapper = "modal-menu-module--list-wrapper--dlw7Q";
export var logoWrapper = "modal-menu-module--logo-wrapper--iddIc";
export var modalContentX = "modal-menu-module--modal-content-x--G2-D9";
export var modalDialogX = "modal-menu-module--modal-dialog-x--rlT3M";
export var modalHeaderX = "modal-menu-module--modal-header-x--wYnD5";